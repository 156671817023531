$HTA-LOGO-PURPLE: #90278e;
$HTA-LOGO-TEAL: #55b4c8;
$HTA-LOGO-BLUE: #1b75bb;
$HTA-LOGO-GREY: #6d6e70;

$HTA-BRANDING-DARKBLUE: #193767;
$HTA-BRANDING-BLUE: #007396;
$HTA-BRANDING-PURPLE: #673065;
$HTA-BRANDING-GREY: #636569;

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css');

//  -----------------------universal styling
body {
  padding-left:48px;
  background-image: url('./images/BackgroundImages/so-white.png');
}

.grey-text-color {
  color: $HTA-LOGO-GREY;
}

#alert-container {
  position: fixed;
  background-color: #ff6666;
  border: 5px;
  z-index: 99999;
  transition: 300ms;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}
.alert {
  padding: 2px;
  margin: 5px;
  opacity: 0.9;
  background: var(--light-color);
  color: white;
}

.purple-accent-font {
  font-family: "Kaushan Script", cursive;
  color: $HTA-LOGO-PURPLE;
  position: relative;
  left: -10px;
  top: 10px;
}
.hover-pointer {
  cursor: pointer;
}
#fixed-menu-wrapper {

  padding-bottom: 200px;
  position: relative;
  min-height: 100vh;
}
hr.style-six {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.react-datepicker-popper {
  z-index:99999 !important;
}


/* ------------------------------------------- header styling starts here */
header {
  border-bottom: 2px solid $HTA-LOGO-PURPLE;
  padding: 20px;
  overflow: hidden;
  background-color:white;
  h3 {
    float: right;
  }
}

//  --------------------------------------------  login and create user styling

#login-form {
  width: 100%;
  max-width: 500px;
  margin: 40px auto;
}

#create-user-page-wrapper {
  a:link,
  a:visited,
  a {
    margin: 10px;
    display: block;
    color: $HTA-LOGO-PURPLE;
  }
}

// ---------------------------------------- dashboard styling
.admin-ticket-form-wrapper {
  .react-datepicker-wrapper {
    input {
      height: 30px;
    }
  }
}
.individual-ticket-reporting-wrapper {
  cursor: pointer;
  h4 {
    margin: 0;
  }
  i {
    float: right;
  }
}
#attachment-view-modal {
  img {
    cursor: pointer;
  }
}
// ----------------------------------------footer styling starts here

footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $HTA-BRANDING-BLUE;
  min-height: 150px;
}

// -------------------------------------- access request housing
.access-focus:focus-within {
  background: rgba(85, 180, 200, 0.7);
}
.accessYes {
  color: green;
  font-family: "Raleway", sans-serif;
}
.accessNo {
  color: red;
  font-family: "Raleway", sans-serif;
}
#conFirmAccessModal {
  p {
    font-weight: bold;
  }
}
.lightAccessConfirm {
  color:grey !important;
  font-weight:normal !important;
}
#accessTipsAndTricks {
  float:right;
  color:red;
  font-size: 14px;
}
#pending-modify-wrapper{
  p {
    font-weight: bold;
  }
}
.active-software-selection {
  background-color:#e6e6e6 !important;
}


// added malik 
.loadingNew {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.loaderNew {
  left: 50%;
  margin-left: -4em;
  font-size: 10px;
  border: 0.8em solid rgba(218, 219, 223, 1);
  border-left: 0.8em solid $HTA-LOGO-PURPLE;
  animation: spin 1.2s infinite linear;
}
.loaderNew,
.loaderNew:after {
  border-radius: 50%;
  width: 8em;
  height: 8em;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -4.05em;
}

@keyframes spin {
  0% {
      transform: rotate(360deg);
  }
  100% {
      transform: rotate(0deg);
  }
}

.mandatory-star::after {
  content: '*';
  font-size: 1em;
  color: red;
}

// added malik end

.colorBrandColor{
  color: $HTA-LOGO-PURPLE;
}

.ui.basic.modal>.close{
  right: -4.5rem;
}

.tile {
  width:140px;
  height:160px;
  border-radius:4px;
  box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.15);
  margin-top:20px;
  margin-left:20px;
  float:left;
}

.tile.wide {
  width: 340px;
}

.tile .header {
  height:120px;
  background-color:#f4f4f4;
  border-radius: 4px 4px 0 0;
  color:white;
  font-weight:300;
}

.tile.wide .header .left, .tile.wide .header .right {
  width:160px;
  float:left;
}

.tile .header .count {
  font-size: 48px;
  text-align:center;
  padding:10px 0 0;
}

.tile .header .title {
  font-size: 20px;
  text-align:center;
}

.tile .body {
  height:60px;
  border-radius: 0 0 4px 4px;
  color:#333333;
  background-color:white;
}

.tile .body .title {
    text-align:center;
    font-size:20px;
    padding-top:12%;
}

.tile.wide .body .title {
  padding:4%;
}

.tile.job .header {
  background: linear-gradient(to bottom right, $HTA-LOGO-PURPLE, $HTA-LOGO-PURPLE);
}

.tile.job  .body {
  color: $HTA-LOGO-PURPLE;
}

.tile.resource .header {
  background: linear-gradient(to bottom right, $HTA-LOGO-BLUE, $HTA-LOGO-BLUE);
}

.tile.resource  .body {
  color: $HTA-LOGO-BLUE;
}

.tile.quote .header {
  background: linear-gradient(to bottom right, $HTA-LOGO-TEAL, $HTA-LOGO-TEAL);
}

.tile.quote  .body {
  color: $HTA-LOGO-TEAL;
}

.tile.invoice .header {
  background: linear-gradient(to bottom right, $HTA-LOGO-GREY, $HTA-LOGO-GREY);
}

.tile.invoice  .body {
  color: $HTA-LOGO-GREY;
}

.circleProfile {
  width: 70px;
  height: 70px;
  line-height: 70px;
  border-radius: 50%;
  font-size: 30px;
  color: #fff;
  text-align: center;
  background: #000;
  float: right
}

.parent {
  width: 150px;
  height: 150px;   
  position: relative;
  top: 40px;
  left: 50px;
  transform: rotate(45deg);   
}
.parent:before {
  content: "";
  width: 140px;
  height: 140px;
  background: #1B7568;            
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
}
.orangeNew {
  width: 100%;
  background: #193767;
  position: absolute;
}
.orangeNew::before, 
.orangeNew::after {
  content: "";
  position: absolute;
  width: 20px;
  background: #193767;           
}
.orangeNew::after {
  right: 0;      
}
.orangeNew:last-of-type {
  bottom: 0;
  transform: scale(-1);

}
.date {      
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  color: white;
  line-height: 0;
  text-align: center;
  padding-top: 25px;
  
}
.redBackground{
  background-color:lightsalmon;
  
}
.greenBackground{
  background-color:lawngreen;
}

.parentFlex {
  display: flex;
  flex-wrap: wrap;
}

.childFlex {
  flex: 1 0 31%; /* explanation below */
  margin: 5px;
  height: 37px;
  font-weight: bolder;
}
.newLabel{
  color: $HTA-LOGO-PURPLE !important;
  font-weight: bold !important;
}

.chip{
  display: inline-flex;
  flex-direction: row; 
  border: none;
  cursor: default;
  height: 36px;
  outline: none;
  padding: 0;
  font-size: 14px;
  margin: 10px;
  font-family:"Open Sans", sans-serif;
  white-space: nowrap;
  align-items: center;
  border-radius: 16px;
  vertical-align: middle;
  text-decoration: none;
  justify-content: center;
}

.chip-content{
color:whitesmoke;
  display: flex;
  align-items: center;
  user-select: none;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
}

.cardNewHire {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  margin: 20px;
  padding: 20px;
}

.cardNewHire:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.containerNewHire {
  padding: 2px 16px;
}

.colorBrandColor2{
  color: #55b4c8 !important;
}
.chip-content-software{
  cursor: inherit;
  display: flex;
  align-items: center;
  user-select: none;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
}
.chip-svg-software{
      color: #999999;
  cursor: pointer;
  height: auto;
  margin: 4px 4px 0 -8px;
fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 24px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  flex-shrink: 0;
}
.chip-svg-software:hover{
  color: #666666;
} 

.btn-new {
  box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 2px solid rgba(0, 195, 255, 0.63);
  border-radius: 0.6em;
  color: rgb(0, 0, 0);
  cursor: pointer;
  display: flex;
  align-self: center;
  font-size: 10px;
  font-weight: 400;
  line-height: 1;
  padding: 10px;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  margin: 3px;
}

//BUTTON 1
.first {
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  &:hover {
    box-shadow: 0 0 40px 40px #55b4c8 inset;
    color:#fff
  }
}

.btn-new-selected{
  background: green !important;
  color:#fff !important;
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  &:hover {
    box-shadow: 0 0 40px 40px green inset;
    color:#fff
  }
}

.vl {
  border-left: 3px solid black;
  height: 78vh;
}

textarea{
  height: 42px !important;
  resize: none !important;
}

.mainContainer {
  margin-top: 10px;
  padding: 0px 140px;
  font-size: 13px;
}

.topHeading {
  padding: 0px 200px;
  text-align: center;
}

.topContent {
  padding: 0px 40px;
}

.label-new {
  font-weight: bold;
}

.flex33 {
  flex-basis: 33.333333%;
}

.flex10 {
  flex-basis: 10%;
}

.input-new {
  border: 2px solid #aaa;
  border-radius: 4px;
  margin: 8px 0;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
  width: 200px;
}

.select-new {
  border: 2px solid #aaa;
  border-radius: 4px;
  margin: 8px 0;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
  background: #fff;
  width: 200px;
}

.textarea-new {
  resize: none;
  border: 2px solid #aaa;
  border-radius: 4px;
  margin: 8px 0;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
  background: #fff;
  width: 100%;
  height: 120px !important;
}

.table-new,
th,
td {
  border: solid 1px #000;
  padding: 10px;
}

.table-new {
  border-collapse: collapse;
  caption-side: bottom;
}

.thirdPage {
  padding: 0px 100px;
  text-align: center;
}

.buttonSubmit {
  text-align: center;
  cursor: pointer;
  font-size: 22px;
  color: white;
  padding: 9px 35px;
  outline: none;
  background-color: #27ae60;
  border: none;
  margin-top: 10px;
  border-radius: 5px;
}

.buttonSubmit:hover {
  background-color: #2ecc71;
}

.buttonSubmit:active {
  background-color: #2ecc71;
  transform: translateY(4px);
}

.table-new{
  width: 100%;
}

.small-text-new-hire{
  float: right;
  font-weight: 600;
  margin-bottom: unset !important;
  font-size: 12px;
}


.customDateStyle {
  .react-datepicker-wrapper {
    width: 100%;
  }
}

.menu {
  .item {
    text-align: left !important;
    position: relative;
    height: 40px !important;
    i {
      width: 40px;
    }
    svg {
      width: 40px;
    }
  }
}

.hoverMenu {
  .logo {
    background-color: #fff;
    width: 100%;

    .logoContainer {
      background-image: url("./images/Logos/hta-main-white.png");
      background-size: cover;
      background-repeat: no-repeat;
      height: 64px;
    }
  }
  width: 70px !important;
  transition: width 1s;
  background-color: rgb(0, 115, 150) !important;

  &:hover {
    width: 250px !important;
    .logo {
      background-color: #fff;
      .logoContainer {
        transition: none;
      }
    }

    .item {
      .showFull {
        width: 300px;
        .hideTitle {
          visibility: visible;
          width: 100%;
          opacity: 1;
        }
      }
    }
  }
  .item {
    overflow: hidden;
    .showFull {
      display: flex;
      .hideTitle {
        visibility: hidden;
        transition: visibility 0.3s linear, opacity 0.3s linear;
        opacity: 0;
        width: 30px;
        width: 100%;
      }
    }
  }

}